.locationAutocompleteContainer {
  margin-bottom: 0.75em;
}

.locationAutocompleteLabel {
  padding-top: 3px;
  padding-bottom: 13px;
}

.ghostDiv {
  display: none;
}