.root {
  margin-bottom: 10px;
}

.label {
  display: block;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey700);
}

.inputError {
  border: var(--borderErrorField);
}